<template>
  <div class="parcel-detail">
    <v-dialog v-model="dialogs.export" max-width="500">
      <v-card>
        <v-card-title>
          Export to
          <v-spacer></v-spacer>
          <v-btn @click="dialogs.export = false" icon>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ExportParcel v-if="data" :parcel="data" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card outlined ref="card" class="parcel-card">
      <v-card-text>
        <peeps-alert-animation
          ref="animation"
          message="Parcel update was Successful!"
        ></peeps-alert-animation>
        <div class="d-flex justify-space-between align-center">
          <h2 style="font-size: 1.1em" class="map-parcel--text">
            {{ removePadding(data.number) }}
          </h2>
          <v-spacer></v-spacer>
          <base-prop-tooltip
            :color="data.landIssueState === 'ISSUE_EXISTS' ? 'warning' : 'success'"
            :tooltip="data.landIssueState === 'ISSUE_EXISTS' ? 'Issues' : 'Issues resolved'"
          >
            <v-icon v-if="data.landIssueState === 'ISSUE_EXISTS'" color="warning">
              mdi-alert-circle-outline
            </v-icon>
            <v-icon v-else-if="data.landIssueState === 'ISSUE_RESOLVED'" color="success">
              mdi-alert-circle-check-outline
            </v-icon>
          </base-prop-tooltip>
          <ParcelReference :reference="data.reference" x-small />
          <v-menu :close-on-content-click="false" transition="expand-transition" min-width="150">
            <template #activator="{ on }">
              <v-btn icon v-on="on" v-show="permissions.create || permissions.delete || isAdmin">
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list nav>
              <v-btn
                @click="dialogs.export = true"
                v-if="isAdmin && exportParcel && data.geojson"
                text
                color="peeps"
                class="text-capitalize"
                block
              >
                Export
              </v-btn>
              <edit-parcel
                v-if="permissions.create"
                text
                :data="data"
                @update="onUpdate"
              ></edit-parcel>
              <delete-parcel
                v-if="permissions.delete"
                text
                block
                :goBack="false"
                @update="onDelete"
                :id="data.id"
              ></delete-parcel>
            </v-list>
          </v-menu>
          <v-btn v-if="showClose" icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <div>
          <span class="font-weight-bold"> {{ data.street_number }} {{ data.street_name }} </span
          ><br />
          <span v-if="data.townCity || data.state">
            {{ data.townCity }}, {{ data.state }}, {{ data.zip }}
          </span>
        </div>

        <div class="mt-3 d-flex">
          <base-prop-tooltip tooltip="Parcel Type" color="info">
            <v-chip v-if="data.parcel_type" color="info" small>{{
              data.parcel_type | fmtParcelType
            }}</v-chip>
          </base-prop-tooltip>
          <v-chip class="mx-1" v-if="data.waterfront" color="success" small>Waterfront</v-chip>
          <v-chip class="mx-1" v-if="data.waterview" color="success" small>Waterview</v-chip>
          <base-prop-tooltip tooltip="Team" color="indigo">
            <v-chip v-if="data.team" color="indigo" small dark class="mx-1">
              {{ data.team }}
            </v-chip>
          </base-prop-tooltip>
        </div>
        <div>
          <v-row class="mt-5">
            <v-col cols="6" class="mt-n5"> Acres: {{ data.acres || '' }} </v-col>
            <v-col cols="6" class="mt-n5" v-if="showHouseLabel && !landOnlyParcel">
              House: {{ data.house_area ? `${data.house_area} SF` : '' }}
            </v-col>
            <v-col cols="6" class="mt-n5" v-if="parcelReference && landOnlyParcel">
              Ref: {{ data.reference }}
            </v-col>
            <v-col cols="6" class="mt-n5" v-if="showBedBathLabel && !landOnlyParcel">
              Bedrooms: {{ data.bedroom || '' }}
            </v-col>
            <v-col cols="6" class="mt-n5" v-if="showBedBathLabel && !landOnlyParcel">
              Bathrooms: {{ data.bathroom || '' }}
            </v-col>
            <v-col cols="12" class="mt-n5" v-if="data.state_class">
              Class: {{ data.state_class || '' }}
            </v-col>
          </v-row>
        </div>
        <v-expand-transition>
          <div v-show="gallery" class="mb-1">
            <div class="outlined pa-1">
              <ParcelGalleryDetail
                show-photo-station
                :parcel-geojson="data.geojson"
                :images.sync="images"
                :key="data.number"
                :parcel-number="data.number"
                :parcel="data.number"
                :parcel-id="data.id"
                ref="gallery"
                @updateCount="updateGalleryCount"
                @click:land="redirectToLandFeature"
              >
                <template #subtitleUpload>
                  <ParcelGalleryTitle :parcel="data" />
                </template>
              </ParcelGalleryDetail>
            </div>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-show="drive">
            <div class="outlined pa-1">
              <Drive
                center-buttons
                small-buttons
                :user="$store.state.loggedInAs.id"
                :folder-id="data.id"
                :otherData="{parcelId: data.id, feature: 'parcel'}"
                hide-copy-id
                @updateCount="updateDriveCount"
              />
            </div>
          </div>
        </v-expand-transition>
        <div
          class="mt-3 pb-2"
          :class="{
            outlined: data.features
              ? featuresEnabled(data.features).length
              : featuresEnabled(featuresComputed).length,
            'outlined-top': data.features
              ? !featuresEnabled(data.features).length
              : !featuresEnabled(featuresComputed).length
          }"
        >
          <div style="position: relative">
            <div class="parcel-gb d-flex" v-if="data">
              <DriveButtonToggle
                v-model="drive"
                class="mr-2"
                :count="driveCount || data.driveCount || 0"
              />
              <ParcelGalleryButton
                v-model="gallery"
                :count="galleryCount || data.galleryCount || 0"
              />
            </div>
          </div>

          <div>
            <div class="feature-group mt-3 ml-3" v-for="(f, index) in featureButtons" :key="index">
              <div v-show="isFeatureAvailable(f.feature, data.features)">
                <div class="d-flex flex-wrap align-center">
                  <div @click="latestParcel(data)">
                    <v-badge
                      :color="colors['light-black']"
                      v-if="featureCount(f.feature)"
                      :content="featureCount(f.feature)"
                      overlap
                    >
                      <feature-button
                        :color="colors['light-black']"
                        :to="featureLink(f.url)"
                        v-model="f.feature"
                        outlined
                      ></feature-button>
                    </v-badge>
                    <feature-button
                      useIconColor
                      :color="colors['light-black']"
                      v-else
                      :to="featureLink(f.url)"
                      outlined
                      v-model="f.feature"
                    ></feature-button>
                  </div>
                  <div>
                    <div>
                      <v-menu v-if="f.about" open-on-hover offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="info" class="info-icon" dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title
                              >{{ f.about.text }}
                              <router-link :to="f.about.url">{{
                                f.about.value
                              }}</router-link></v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                  <septic-system-information
                    class="mt-2"
                    v-if="
                      getInformation(f.feature) &&
                      f.feature == 'septic' &&
                      getInformation(f.feature).component_type
                    "
                    :data="getInformation(f.feature)"
                    :parcelId="data.id"
                  >
                  </septic-system-information>
                  <water-source-information
                    class="mt-2"
                    v-if="
                      f.feature == 'water' &&
                      getInformation(f.feature) &&
                      getInformation(f.feature).water_source
                    "
                    :data="getInformation(f.feature)"
                    :parcelId="data.id"
                  ></water-source-information>

                  <hvac-information
                    :value="getInformation(f.feature)"
                    class="mt-2"
                    v-if="
                      f.feature == 'hvac' &&
                      getInformation(f.feature) &&
                      fuelSources(getInformation(f.feature)).length
                    "
                    :parcelId="data.id"
                  ></hvac-information>

                  <LandInformation
                    :data="getInformation(f.feature)"
                    class="mt-2"
                    v-if="
                      f.feature === 'land' &&
                      getInformation(f.feature) &&
                      getInformation(f.feature).type
                    "
                    :parcelId="data.id"
                  />
                  <div v-if="f.feature === 'land' && data.landGalleryCount" class="mt-2 mx-2">
                    <FeatureGalleryButton
                      tooltip="Land Gallery"
                      :value="false"
                      :count="data.landGalleryCount"
                      :to="`/parcels/${data.id}/land?gallery=true`"
                    />
                  </div>
                  <v-btn
                    icon
                    @click="$refs.gallery.showActionDialog()"
                    class="mt-2"
                    v-if="f.feature === 'land' && $store.state.loggedInAs.type === 'organization'"
                  >
                    <v-icon>mdi-arrow-decision-outline</v-icon>
                  </v-btn>
                  <div
                    v-if="data.mddUpdated && f.feature == 'land'"
                  >
                  <router-link
                  class="d-flex flex-wrap mt-2 mb-1 land-btn"
 :to="`/parcels/${data.id}/land?add=true`">
                    <div class="mx-1">
                      Monitor:
                      <span> Last {{ data.lmd ? formatDate(data.lmd) : 'NA' }} </span>
                    </div>
                    <div class="mx-1">
                      <base-prop-tooltip
                        tooltip="Monitoring frequency (months)"
                        :color="monitoringStatus(data.lmd, data.monitoringFrequency).color"
                      >
                        <v-btn
                          v-if="data.monitoringFrequency"
                          class="ml-1"
                          outlined
                          x-small
                          :color="monitoringStatus(data.lmd, data.monitoringFrequency).color"
                          label
                        >
                          {{ data.monitoringFrequency }}
                        </v-btn>
                      </base-prop-tooltip>
                    </div>
                    <div class="mx-1">
                      <span
                        :style="`color: ${
                          monitoringStatus(data.lmd, data.monitoringFrequency).color
                        }`"
                      >
                        Next {{ data.mdd ? formatDate(data.mdd) : 'Today' }}
                      </span>
                    </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ParcelMapThumbnail
          :images="images"
          :parcel="data"
          :key="data.number"
          :hide-thumbnail-image="hideThumbnailImage"
          :no-map-redirect="noMapRedirect"
          @showGallery="showGallery"
          @close="$emit('close')"
        />
        <div class="mt-3 pa-2">
          <property-card-key
            :data="data"
            text="Card Key"
            v-if="data.key || stateTown('MA', 'Truro')"
          >
          </property-card-key>
          <v-btn
            width="170"
            v-if="stateTown('MA', 'Truro')"
            class="text-none mt-2 mr-2"
            color="info"
            @click="showAssessorData"
            small
            dark
          >
            <v-icon>mdi-currency-usd</v-icon>
            Assessor Data
          </v-btn>
          <v-btn
            width="170"
            v-if="stateTown('MA', 'Truro')"
            class="text-none mt-2 mr-2"
            color="info"
            small
            dark
            @click="showAssessorMap"
          >
            <v-icon>mdi-map-marker</v-icon>
            Assessor Map
          </v-btn>
          <v-menu v-model="dialogs.snlMenu">
            <template v-slot:activator="{ on }">
              <v-btn
                v-show="showNitrogenFootprint"
                v-on="on"
                width="170"
                class="text-none mt-2 mr-2"
                color="info"
                small
                dark
              >
                <v-icon></v-icon>
                Nitrogen Footprint
              </v-btn>
            </template>
            <v-card max-width="400">
              <v-card-title :style="`background-color:${getNitrateColor(calculateSnl(data))}`">
                Simple Nitrogen Load
                <v-spacer></v-spacer>
                <v-btn @click="dialogs.snlMenu = false" icon>
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  <h3 class="text-h6">{{ calculateSnl(data) }}</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DeleteParcel from '@/components/Parcel/DeleteParcel';
import EditParcel from '@/components/Parcel/EditParcel';
import SepticSystemInformation from '@/components/Septic/SystemInformation';
import PropertyCardKey from './PropertyCardKey.vue';
import WaterSourceInformation from '@/components/Water/SourceInformation';
import HvacInformation from '@/components/HVAC/HVACInformation';
import LandInformation from '@/components/Land/Information';

import ParcelGalleryDetail from '@/components/Gallery/ParcelGalleryDetail';
import PeepsAlertAnimation from '@/components/Core/Alerts/PeepsAlertAnimation.vue';

import parcel from '@/mixins/parcel';
import septic from '@/mixins/septic';
import feature from '@/mixins/feature';
import utils from '../../mixins/utils';
import map from '../../mixins/map';
import { mapGetters, mapMutations, mapState } from 'vuex';
import Parcel from '../../services/parcel';
import Land from '../../services/land';

import colors from '../../design/_colors.scss';
import DriveButtonToggle from '../../components/Drive/ButtonToggle.vue';
import Drive from '../../components/Drive/index.vue';
import { isLandOnlyParcel } from '../../utils/parcel';
import { combineFeatures } from '../../utils/feature';
import moment from 'moment';

export default {
  mixins: [parcel, feature, septic, utils, map],
  props: {
    hideThumbnailImage: {
      type: Boolean,
      default: false
    },
    data: {},
    noMapRedirect: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    },
    exportParcel: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DeleteParcel,
    EditParcel,
    SepticSystemInformation,
    PropertyCardKey,
    WaterSourceInformation,
    HvacInformation,
    ParcelGalleryDetail,
    DriveButtonToggle,
    Drive,
    FeatureButton: () => import('@/components/Core/Features/FeatureButton.vue'),
    PeepsAlertAnimation,
    LandInformation,
    ParcelMapThumbnail: () => import('./DetailMap.vue'),
    ExportParcel: () => import('./Export.vue'),
    ParcelReference: () => import('./Reference.vue'),
    ParcelGalleryTitle: () => import('./GalleryTitle.vue'),
    ParcelGalleryButton: () => import('../../components/Gallery/ParcelGalleryButton.vue'),
    FeatureGalleryButton: () => import('../../components/Gallery/FeatureGalleryButton.vue')
  },
  filters: {
    fmtParcelType(type) {
      const p = new Parcel();
      return p.type(type);
    }
  },
  data: () => ({
    tooltip: false,
    taxCount: {},
    dialogs: {
      export: false
    },
    gallery: false,
    galleryCount: 0,
    driveCount: 0,
    drive: false,
    focused: false,
    images: [],
    photoStation: false
  }),
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    landOnlyParcel() {
      if (!this.data) {
        return false;
      }
      return isLandOnlyParcel(this.data.parcel_type);
    },
    parcelURL() {
      return `/parcels/${this.data.id}`;
    },
    features() {
      return this.$store.getters.user.profile.features;
    },
    componentType() {
      let res = null;
      if (this.septicCount && this.septicCount.system.component_type) {
        res = this.getSepticComponent(this.septicCount.system.component_type);
      }
      return res;
    },
    featureButtons() {
      return this.$store.getters.featuresList;
    },
    colors() {
      return colors;
    },
    profile() {
      return this.$store.getters.user.profile;
    },
    showHouseLabel() {
      return this.parcels.some(parcel => parcel.house_area);
    },
    showBedBathLabel() {
      return this.parcels.some(parcel => parcel.bedroom || parcel.bathroom);
    },
    showNitrogenFootprint() {
      if (
        !this.data ||
        this.data.parcel_type === 'openSpace' ||
        this.data.parcel_type === 'lotWetland'
      ) {
        return false;
      }
      return true;
    },
    ...mapGetters(['parcels', 'scaleFilter', 'parcelReference']),
    ...mapState(['permissions'])
  },
  updated() {
    if (this.focused) {
      return;
    }
    const t = Number(this.$route.query.t) || 1000;
    this.focused = true;
    if (this.highlight) {
      setTimeout(() => {
        const ref = this.$refs.card;
        ref.$el.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          ref.$el.classList.add('highlight-parcel');
        }, 200);
      }, t);
    }
  },
  methods: {
    redirectToLandFeature(value) {
      this.$router.push(`/parcels/${this.data.id}/land?${value ? 'add=true' : ''}`);
    },
    showGallery(id) {
      this.$refs.gallery.$refs.gallery.showDetail({ id });
    },
    updateDriveCount(value) {
      this.driveCount = value;
      if (value && value !== this.data.driveCount) {
        const q = {
          feature: 'metrics',
          action: 'update',
          parcelId: this.data.id,
          parcelData: {
            driveCount: value
          }
        };
        this.updateMetrics(q);
      }
    },
    updateGalleryCount(value) {
      this.galleryCount = value;
      if (value && value !== this.data.galleryCount) {
        const q = {
          feature: 'metrics',
          action: 'update',
          parcelId: this.data.id,
          parcelData: {
            galleryCount: value
          }
        };
        this.updateMetrics(q);
      }
    },
    formatDate(date) {
      const dateParsed = moment(date);
      if (dateParsed.isValid()) {
        return dateParsed.format('YYYY-MM');
      } else {
        return '';
      }
    },
    monitoringStatus(lmd, due) {
      const l = new Land();
      return l.monitoringStatus({ lmd, due });
    },
    combineFeatures,
    fuelSources(data) {
      let fuelSources = [];
      if (data) {
        let i = data;
        for (let s of this.$store.getters.HVACSources) {
          let checked = i[s.value] && i[s.value].value;
          if (checked) {
            fuelSources.push(s);
          }
        }
      }
      return fuelSources;
    },
    onDelete() {
      this.$emit('close');
      this.$emit('deleted');
    },
    featureLink(feature) {
      return `${this.parcelURL}/${feature}`;
    },
    stateTown(state, town) {
      let res = false;
      if (this.data.state && this.data.townCity) {
        if (
          this.data.state.toLowerCase() == state.toLowerCase() &&
          this.data.townCity.toLowerCase() == town.toLowerCase()
        ) {
          return (res = true);
        }
      }
      return res;
    },
    showAssessorMap() {
      let URL = 'https://www.mapsonline.net/truroma/?ts_cama_id=';
      let parcelNumber = this.data.number;
      URL += this.removePadding(parcelNumber);
      window.open(URL, '_blank');
    },
    showAssessorData() {
      let URL = 'https://www.mapsonline.net/truroma/web_assessor/search.php#pid=';
      let parcelNumber = this.data.number;
      URL += this.removePadding(parcelNumber);
      window.open(URL, '_blank');
    },
    featureCount(f) {
      if (this.getFeatureInfo(f)) {
        return this.getFeatureInfo(f).count;
      } else {
        return null;
      }
    },
    findOrigFeatures(val) {
      let f = this.$store.getters.features.filter(e => e.value == val);
      if (f) {
        f = f[0];
      } else {
        f = {};
      }
      return f;
    },
    septicSystemURL(id) {
      return `/parcels/${id}/septic?septic_card=true`;
    },
    onUpdate() {
      const view = this.$route.path.split('/').pop();
      if (view == 'list') {
        this.$refs.animation.animate();
      }
      this.$emit('update');
    },
    getFeatureInfo(feature) {
      if (this.data.metrics && this.data.metrics[feature]) {
        return this.data.metrics[feature];
      } else {
        return null;
      }
    },
    getInformation(feature) {
      if (this.getFeatureInfo(feature) && this.getFeatureInfo(feature).information) {
        return this.getFeatureInfo(feature).information;
      } else {
        return null;
      }
    },
    ...mapMutations(['latestParcel'])
  }
};
</script>

<style scoped lang="sass">
@import "../../design/_colors.scss"
.address
  color: #2a3331

.info-icon
  /* margin-left: -6px !important; */
  margin-top: 5px
  /* margin-right: 3px; */

.septic-f-column
  display: flex
  flex-direction: column
  display: inline-flex
  margin-right: 2px

.septic-features
  margin-top: -20px

.map-container
  .map-hint
    color: $color-light-black

  .map-clickable
    cursor: pointer

.map-card--title
  // font-size: 16px !important
  height: 50px
  margin-top: -13px

@media (max-width:600px)
  .map-card--title
    font-size: 14px !important

.mapboxgl-popup--opacity-60
  opacity: 0.6

.parcel-gb
  position: absolute
  right: 0
  margin: -18px 5px 0 0

.land-btn
    text-decoration: none
    color: inherit
    background-color: #ebedf0
    padding: 3px
    margin-right: 8px
    border-radius: 5px
</style>
